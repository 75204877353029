import { createElement } from 'react';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import rehypeReact from 'rehype-react';

export const ERRORS = {
  REQUIRED: 'Ce champ est requis',
  TAKEN: 'Cette valeur est déjà prise',
  INVALID: 'Cette valeur n’est pas valide',
  EMAIL: 'Cette adresse mail n’est pas valide',
  EMAIL_TAKEN: 'Cette adresse mail est déjà utilisée',
};

export const formatDate = (date, frmt) => {
  if (!Boolean(date)) {
    return '';
  }

  if (!Boolean(frmt)) {
    try {
      return new Date(date).toISOString();
    } catch (err) {
      return '';
    }
  }

  try {
    return format(new Date(date), frmt, { locale: fr });
  } catch (err) {
    try {
      return format(date, frmt, { locale: fr });
    } catch (err) {
      return '';
    }
  }
};

export const renderHtml = new rehypeReact({
  createElement: createElement,
  components: { h1: 'h2', h2: 'h3', h3: 'h4', h4: 'h5', h5: 'h6', h6: 'p' },
}).Compiler;
